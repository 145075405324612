import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  getTotalPaid,
  getMinOrderAmountFee,
  getTotalDiscount,
} from '../../core/services/orderPrice';
import { colors, mobileThresholdPixels } from '../home/v3/styledComponents';
import OrderSummaryCloth from './OrderSummaryCloth';
import { pricingInverse } from '../../services/pricing';

const TextContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  ${({ marginTop }) => marginTop && 'margin-top: 80px;'}
  ${({ noMarginBottom }) => !noMarginBottom && 'margin-bottom: 15px;'}
  @media (max-width: ${mobileThresholdPixels}) {
    ${({ marginTop }) => marginTop && 'margin-top: 60px;'}
  }
`;

const Text = styled.p`
  font-family: Roboto;
  font-weight: 500;
  font-size: 18px;
  text-align: left;
  margin: 0px;
  ${({ marginBottom }) => marginBottom && 'margin-bottom: 12px;'}
  ${({ marginTop }) => marginTop && 'margin-top: 20px;'}
  color: ${({ grey }) => grey ? colors.lightGrey4 : colors.navy};
  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 14px;
    ${({ marginBottom }) => marginBottom && 'margin-bottom: 8px;'}
    ${({ marginTop }) => marginTop && 'margin-top: 18px;'}
  }
`;

const Line = styled.div`
  width: 100%;
  border-top: 0.75px solid ${colors.lightGrey4};
  margin: ${({ smallMarginTop }) => smallMarginTop ? '0' : '20'}px 0px 20px;
  ${({ noMarginBottom }) => noMarginBottom && 'margin-bottom: 0px;'}
  @media (max-width: ${mobileThresholdPixels}) {
    margin: ${({ smallMarginTop }) => smallMarginTop ? '8' : '18'}px 0px 18px;
    ${({ noMarginBottom }) => noMarginBottom && 'margin-bottom: 0px;'}
  }
`;

const clothTypesOfWorks = {
  alteration: 'retoucher',
  embroidery: 'broder',
  transformation: 'upcycler',
  creation: 'créer',
};

const getClothName = (cloth) => {
  let clothName = '';
  if (!cloth.name) {
    try {
      clothName = pricingInverse[cloth.items[0].id].cloth;
    } catch (e) {
      return null;
    }
  } else {
    clothName = cloth.name;
  }

  if (cloth.typesOfWork?.length > 0) {
    clothName = `${clothName} à ${cloth.typesOfWork
      .reduce((typesOfWork, typeOfWork, index) => {
        let linkWord = '';
        if (index !== cloth.typesOfWork.length - 1) linkWord = ', ';
        if (index === cloth.typesOfWork.length - 2) linkWord = ' et ';
        return (`${typesOfWork}${clothTypesOfWorks[typeOfWork]}${linkWord}`);
      }, '')}`;
  }
  clothName = clothName.replace(' ()', '');
  return clothName;
};

const OrderSummary = ({ order }) => {
  const totalPaid = getTotalPaid(order);
  const minOrderAmountFee = getMinOrderAmountFee(order);
  const discount = getTotalDiscount(order);
  return (
    <div>
      <Line smallMarginTop noMarginBottom />
      {order.clothes?.map((cloth) => {
        if (!getClothName(cloth)) return <div key={cloth._id} />;
        return (
          <div key={cloth._id}>
            <Text marginBottom marginTop>{getClothName(cloth)}</Text>
            <OrderSummaryCloth
              cloth={cloth}
              key={cloth.name}
              pricingVersion={order.pricingVersion}
            />
          </div>
        );
      })}
      {(minOrderAmountFee > 0) &&
        <TextContainer marginTop>
          <Text grey>Minimum de commande</Text>
          <Text grey>{order.minOrderAmount}€</Text>
        </TextContainer>
      }
      <TextContainer marginTop={minOrderAmountFee === 0}>
        <Text>Frais de déplacement</Text>
        <Text>{order.deliveryFee} €</Text>
      </TextContainer>
      {discount > 0 &&
        <TextContainer>
          <Text>Réduction</Text>
          <Text grey>- {discount} €</Text>
        </TextContainer>
      }
      <Line />
      <TextContainer noMarginBottom>
        <Text>Prix total</Text>
        <Text>{totalPaid} €</Text>
      </TextContainer>
    </div>
  );
};

OrderSummary.propTypes = {
  order: PropTypes.shape({}).isRequired,
};


export default OrderSummary;
