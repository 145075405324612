import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import withCustomerContext from '../../withCustomerContext';
import Layout from '../../layouts';
import { colors, Container as ContainerV3, mobileThresholdPixels } from '../../components/home/v3/styledComponents';
import MenuBar from '../../components/home/MenuBar';
import Footer from '../../components/home/v3/Footer';
import { PageContainer } from '../../components/MyAccount/myAccountStyledComponents';
import Menu from '../../components/MyAccount/Menu';
import OrderDetails from '../../components/MyAccount/OrderDetails';

const Container = styled(ContainerV3)`
  background-color: ${colors.white};
`;

const MyAccountContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const SubContainer = styled.div`
  display: flex;
  width: 1440px;
  @media (max-width: ${mobileThresholdPixels}) {
    width: 100%;
    flex-direction: column;
  }
`;

class MyOrderDetails extends React.Component {
  constructor() {
    super();
    this.state = {
      isLoginPopupOpened: false,
    };
    this.openLoginPopup = this.openLoginPopup.bind(this);
  }

  componentDidMount() {
    const { customerContext: { fetchCustomer } } = this.props;
    fetchCustomer().then((customer) => { if (!customer) this.openLoginPopup(); });
  }

  openLoginPopup() {
    this.setState({ isLoginPopupOpened: true });
  }

  render() {
    const { isLoginPopupOpened } = this.state;
    const { customerContext: { selectedOrder } } = this.props;
    return (
      <Layout routeSlug="MyOrderDetails">
        <Container>
          <MenuBar isLoginPopupOpened={isLoginPopupOpened} />
          <MyAccountContainer>
            <SubContainer>
              <Menu selectedAccountCategory="MyOrders" />
              <PageContainer>
                <OrderDetails order={selectedOrder} />
              </PageContainer>
            </SubContainer>
          </MyAccountContainer>
          <MyAccountContainer selectedAccountCategory="MyOrders" />
          <Footer />
        </Container>
      </Layout>
    );
  }
}

MyOrderDetails.propTypes = {
  customerContext: PropTypes.shape({
    fetchCustomer: PropTypes.func,
    selectedOrder: PropTypes.shape({}),
  }).isRequired,
};

export default withCustomerContext(MyOrderDetails);
