import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment-timezone';

import withAppContext from '../../withAppContext';
import { getWrittenDate, getGroupedSlotsPerDay } from '../../services/slotsFormatting';
import { colors, mobileThresholdPixels, Button as ButtonV3 } from '../home/v3/styledComponents';
import OrderSummary from './OrderSummary';
import PopupOrderFeedback from './PopupOrderFeedback';
import momentFr from '../../tilli-components/src/config/moment.fr';
import routesMap from '../../Routes';

moment.updateLocale('fr', momentFr);
moment.tz.setDefault('Europe/Paris');

const orderOtherInfosRows = [
  '1er rendez-vous :',
  'Adresse :',
  'Tilliste :',
  'Rendu :',
  'RDV réajustement:',
];

const OrderDetailsContainer = styled.div`
  margin-top: 20px;
`;

const Title = styled.div`
  font-weight: 500;
  font-size: 26px;
  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 18px;
    border-bottom: 0.5px solid ${colors.navy};
    padding-bottom: 13px;
  }
`;

const Container = styled.div`
  display: flex;
  @media (max-width: ${mobileThresholdPixels}) {
    flex-direction: column;
  }
`;

const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${({ marginTop }) => marginTop && 'margin-top: 26px;'}
  ${({ fixWidth }) => fixWidth ? 'width: 543px;' : 'flex-grow: 1;'}
  @media (max-width: ${mobileThresholdPixels}) {
    width: 100%;
  }
`;

const InfoContainer = styled.div`
  display: flex;
  ${({ column }) => column && 'flex-direction: column;'}
  margin-bottom: 20px;
  @media (max-width: ${mobileThresholdPixels}) {
    margin-bottom: 10px;
  }
`;

const InfoTitle = styled.span`
  font-weight: 500;
  margin: 0px 5px 0px 0px;
`;

const InfoValue = styled.span`
  color: ${colors.lightGrey4};
  margin: 0px;
`;

const OtherInfosContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px 19px 31px;
  background-color: ${colors.lightGrey};
  padding: 26px 29px;
  @media (max-width: ${mobileThresholdPixels}) {
    margin: 22px 0px 18px;
  }
`;

const TextLine = styled.p`
  margin: 0px;
  ${({ marginBottom }) => marginBottom && 'margin-bottom: 20px;'}
  @media (max-width: ${mobileThresholdPixels}) {
    ${({ marginBottom }) => marginBottom && 'margin-bottom: 10px;'}
  }
`;

const Button = styled(ButtonV3)`
  margin-top: 40px;
  width: 177px;
  min-width: unset;
  height: 44px;
  min-height: unset;
  font-size: 14px;
  line-height: 16px;
  align-self: flex-start;
  ${({ centered }) => centered && 'align-self: center;'}
  @media (max-width: ${mobileThresholdPixels}) {
    margin: 29px 0px ${({ small }) => small ? '21' : '82'}px;
  }
`;

const ContactLine = styled.p`
  font-size: 14px;
  color: ${colors.lightGrey4};
  text-align: center;
  margin: 0px 0px 32px;
`;

const A = styled.a`
  color: inherit;
  margin-left: 5px;
  ${props => props.noDecoration && 'text-decoration: none;'}
`;

const OpacityContainer = styled.div`
  background: ${colors.white};
  opacity: 0.8;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3;
`;

const Line = styled.div`
  width: 100%;
  border-top: 0.5px solid ${colors.lightGrey2};
  margin-top: 59px;
`;

const getFirstRDVPrefixAndSlots = (state, rdv1, rdv1Slots) => {
  const firstRDV = {};
  switch (state) {
    case 'ACTIVATION':
    case 'WAITING_COUTURIER_ANSWER':
    case 'WAITING_COUTURIER_ANSWER_CHANGE_RDV1':
    case 'COUTURIER_REFUSAL':
      firstRDV.prefix = "En cours d'assignation pour";
      firstRDV.slots = rdv1Slots;
      break;
    default:
      if (state === 'ORDER_CONFIRMATION') firstRDV.prefix = 'Confirmé pour';
      firstRDV.slots = [rdv1];
  }
  return firstRDV;
};

const getFirstRDVInfos = (state, rdv1, rdv1Slots) => {
  const { prefix, slots } = getFirstRDVPrefixAndSlots(state, rdv1, rdv1Slots);
  const groupedSlotsPerDay = getGroupedSlotsPerDay(slots);
  return groupedSlotsPerDay
    .map(oneDaySlots => (
      oneDaySlots.slots.reduce((accLine, slot, index, slotsList) => {
        const { rdvDate, rdvBegin, rdvEnd } = getWrittenDate(slot, index, slotsList);
        return rdvEnd ? `${accLine} ${rdvDate} de ${rdvBegin} à ${rdvEnd}` : `${accLine} ${rdvDate} ${rdvBegin}`;
      }, '')).replace(/ ,/g, ','))
    .map((dateLine, index) => {
      if (index === 0) {
        return prefix !== undefined ? `${prefix} ${dateLine.toLowerCase()}` : dateLine;
      }
      return `ou${dateLine.toLowerCase()}`;
    });
};

const getGiveBackInfos = (rdv2, deliverySlot) => {
  const giveBack = {};
  if (rdv2 || deliverySlot) {
    giveBack.slot = rdv2 || deliverySlot;
    giveBack.prefix = rdv2 ? 'Par le Tilliste, ' : 'Par coursier, ';
    const { rdvDate, rdvBegin, rdvEnd } = getWrittenDate(giveBack.slot, 0, [giveBack.slot]);
    giveBack.line = `${giveBack.prefix} ${rdvDate.toLowerCase()} de ${rdvBegin} à ${rdvEnd}`;
  }
  return giveBack.line;
};

const getReadjustmentInfos = (rdv3) => {
  const readjustment = {};
  if (rdv3) {
    readjustment.prefix = moment(rdv3.begin).isBefore(moment()) ? 'Prévu ' : '';
    const { rdvDate, rdvBegin, rdvEnd } = getWrittenDate(rdv3, 0, [rdv3]);
    readjustment.rdvDate = readjustment.prefix !== '' ? rdvDate.toLowerCase() : rdvDate;
    readjustment.line = `${readjustment.prefix} ${readjustment.rdvDate} de ${rdvBegin} à ${rdvEnd}`;
  }
  return readjustment.line;
};

const getOrderOtherInfos = ({
  state,
  rdv1, rdv1Slots,
  fullAddress,
  couturier,
  rdv2, deliverySlot,
  rdv3,
}) => {
  const firstRDV = getFirstRDVInfos(state, rdv1, rdv1Slots);
  const address = [`${fullAddress.street} ${fullAddress.zipcode} ${fullAddress.locality}`];
  const tilliste = [couturier ? couturier.firstname : 'Pas encore assigné'];
  const giveBack = [getGiveBackInfos(rdv2, deliverySlot)];
  const readjustment = [getReadjustmentInfos(rdv3)];

  return [firstRDV, address, tilliste, giveBack, readjustment];
};

class OrderDetails extends React.Component {
  constructor() {
    super();
    this.state = {
      isPopupOpened: false,
    };
    this.setIsPopupOpened = this.setIsPopupOpened.bind(this);
  }

  setIsPopupOpened(isPopupOpened) {
    this.setState({ isPopupOpened });
  }

  render() {
    const { order, context: { isMobile } } = this.props;
    if (!order) return null;
    const { isPopupOpened } = this.state;
    const { orderValues, orderRows } = order;
    const mainInfosRows = orderRows.slice(0, 3);
    const orderOtherInfosValues = getOrderOtherInfos(order);
    const orderHasNoFeedback = (order.state === 'DONE' || order.state === 'WAITING_PAYMENT_COUTURIER')
      && !order.customerFeedbackRating;
    return (
      <OrderDetailsContainer>
        <Title>Détails de la commande</Title>
        <Container>
          <SubContainer marginTop>
            {mainInfosRows.map((info, index) => (
              <TextLine key={info} marginBottom>
                <InfoTitle>{info} :</InfoTitle>
                <InfoValue>{orderValues[index]}</InfoValue>
              </TextLine>
            ))}
            {!isMobile && (orderHasNoFeedback
              ? <Button navy onClick={() => this.setIsPopupOpened(true)}>Donner un avis</Button>
              : <Line />
            )}
            {!order.cardToken &&
              <Button navy centered>
                <A
                  href={`${routesMap.Payment.url}/${order._id}/${order.customer._id}`}
                  noDecoration
                  target="_blank"
                >Renseigner votre cb</A>
              </Button>
            }
          </SubContainer>
          <SubContainer fixWidth>
            <OtherInfosContainer>
              {orderOtherInfosRows.map((info, index) => {
                if (!orderOtherInfosValues[index][0]) return null;
                return (
                  <InfoContainer key={info} column>
                    <TextLine>
                      <InfoTitle>{info}</InfoTitle>
                      <InfoValue>{orderOtherInfosValues[index][0]}</InfoValue>
                    </TextLine>
                    {orderOtherInfosValues[index].length > 1 && orderOtherInfosValues[index].map((line, indexLine) => {
                      if (indexLine === 0) return null;
                      return <InfoValue key={line}>{line}</InfoValue>;
                    })}
                  </InfoContainer>
                );
              })}
              <OrderSummary order={order} />
            </OtherInfosContainer>
            <ContactLine>Des remarques sur vos rendez-vous ? N’hésitez pas à nous contacter à :
              <A href="mailto:contact@tilli.fr">contact@tilli.fr</A>
            </ContactLine>
            {isMobile && orderHasNoFeedback &&
              <Button navy onClick={() => this.setIsPopupOpened(true)}>Donner un avis</Button>
            }
          </SubContainer>
        </Container>
        {isPopupOpened &&
          <div>
            <PopupOrderFeedback
              closePopup={() => this.setIsPopupOpened(false)}
              orderId={order._id}
            />
            <OpacityContainer />
          </div>
        }
      </OrderDetailsContainer>
    );
  }
}

OrderDetails.propTypes = {
  order: PropTypes.shape({}),
  context: PropTypes.shape({
    isMobile: PropTypes.bool,
  }).isRequired,
};

OrderDetails.defaultProps = {
  order: undefined,
};

export default withAppContext(OrderDetails);
